import React, { useContext } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { SocialMediaList } from 'gatsby-theme-sanity-evelan/src/components/social-media-list/social-media-list';
import { Button } from 'gatsby-theme-sanity-evelan/src/components/button/button';
import { CookiesShowContext } from 'gatsby-theme-sanity-evelan/src/components/cookies/cookies-context/cookies-context';
import styles from './footer.module.scss';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';

export function Footer({ footerLinks, socialMedia, companyInfo = {} }) {
  const { setShowCookies } = useContext(CookiesShowContext);
  const copyRight = `© ${new Date().getFullYear()} Profilium GmbH`;

  const privacyLinks = [
    {
      name: 'Impressum',
      link: '/impressum',
    },
    {
      name: 'Datenschutzerklärung',
      link: '/datenschutzerklaerung',
    },
    {
      name: 'AGB’s',
      link: '/agbs',
    },
  ];

  return (
    <div className={styles.backgroundWrapper}>
      <Container variant="thin" className={styles.wrapper} tag="footer">
        <div className={styles.footerColumnContainer}>
          <div className={clsx(styles.footerColumn, styles.description)}>
            <div className={styles.brandName}>{companyInfo.name}</div>
            <div className={styles.contactInfo}>
              <div>{companyInfo.street}</div>
              <div>{`${companyInfo.zip} ${companyInfo.city}`}</div>
              <a href={`tel: ${companyInfo.phoneHotline}`}>{companyInfo.phoneHotline}</a>
              <a href={`mailto: ${companyInfo.email}`}>{companyInfo.email}</a>
            </div>
          </div>
          <div className={clsx(styles.footerColumn, styles.links)}>
            {footerLinks.map(({ name, link }, index) => (
              <LinkButton
                key={`footer-link-${index}`}
                to={link}
                className={styles.footerLinks}
                activeClassName={styles.activeNav}
              >
                {name}
              </LinkButton>
            ))}
          </div>
          <div className={clsx(styles.footerColumn, styles.privacyLinks)}>
            {privacyLinks.map(({ name, link }, index) => (
              <Link
                key={`privacy-link-${index}`}
                to={link}
                className={styles.footerPrivacyLinks}
                activeClassName={styles.activeNav}
              >
                {name}
              </Link>
            ))}
            <Button
              variant="text"
              className={styles.footerPrivacyLinks}
              key="cookies-button-key"
              onClick={() => setShowCookies(true)}
            >
              Cookies
            </Button>
          </div>
          <div className={clsx(styles.footerColumn, styles.social)}>
            <SocialMediaList socialMedia={socialMedia} className={styles.socials} />
          </div>
        </div>
        <div className={styles.copyRightText}>{copyRight}</div>
      </Container>
    </div>
  );
}
