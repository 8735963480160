import React from 'react';
import clsx from 'clsx';
import styles from './social-icon.module.scss';
import YoutubeIcon from '../../images/icons/social/youtube-icon.svg';
const SocialYoutubeIcon = () => {
  return (
    <div className={clsx(styles.iconContainer, styles.youtube)}>
      <img src={YoutubeIcon} width="20" height="20" alt="youtube" />
    </div>
  );
};

export default SocialYoutubeIcon;
