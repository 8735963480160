import React from 'react';
import styles from './burger.module.scss';

export function BurgerMenu({ isOpen, onClick }) {
  return (
    <div className={styles.burgerContainer}>
      <button
        name="menu-button"
        aria-label="Toggle menu"
        className={`${styles.burger} ${isOpen && styles.active}`}
        onClick={onClick}
        type="button"
      >
        <span className={styles.box} aria-hidden="true">
          <span className={styles.inner} />
        </span>
      </button>
    </div>
  );
}

export default BurgerMenu;
