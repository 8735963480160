import React from 'react';
import clsx from 'clsx';
import styles from './social-icon.module.scss';

const SocialXingIcon = () => {
  return (
    <div className={clsx(styles.iconContainer, styles.xing)}>
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.93397 6.3555C5.86771 6.47158 5.01622 7.94194 3.37948 10.7666C3.20057 11.0633 2.98521 11.2116 2.7334 11.2116H0.357828C0.218672 11.2116 0.115963 11.1568 0.0496984 11.0472C-0.0165661 10.9375 -0.0165661 10.8214 0.0496984 10.6989L2.56443 6.36518C2.57106 6.36518 2.57106 6.36195 2.56443 6.3555L0.964146 3.65659C0.884629 3.51471 0.881316 3.39541 0.954207 3.29867C1.01385 3.20193 1.11987 3.15357 1.27228 3.15357H3.64785C3.91291 3.15357 4.13158 3.29867 4.30387 3.58888L5.93397 6.3555ZM13.9453 0.145103C14.0182 0.248288 14.0182 0.367593 13.9453 0.503023L8.6972 9.53809V9.54776L12.0369 15.497C12.1098 15.626 12.1131 15.7453 12.0469 15.8549C11.9806 15.9516 11.8746 16 11.7288 16H9.35321C9.0749 16 8.85623 15.8549 8.6972 15.5647L5.32765 9.54776C5.44693 9.34139 7.20623 6.30394 10.6056 0.435308C10.7713 0.145101 10.9833 0 11.2417 0H13.6372C13.783 0 13.8857 0.0483671 13.9453 0.145103Z"
          fill="#201E1E"
        />
      </svg>
    </div>
  );
};

export default SocialXingIcon;
