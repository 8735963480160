import React from 'react';
import PropTypes from 'prop-types';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import { Link } from 'gatsby';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';
import PlayButton from 'gatsby-theme-sanity-evelan/src/images/icons/play-button-white.svg';
import clsx from 'clsx';
import styles from './post-card.module.scss';
import { extractExcerptFromArticle } from 'gatsby-theme-sanity-evelan/src/components/blog/blog-utils';

const PostCard = ({ mainImage, mainVideo, publishedDate, category, title, color, slug, body }) => {
  const to = `/blog/${slug}`;
  const hasVideo = !!mainVideo && typeof mainVideo.url === 'string' && mainVideo.url.trim().length > 0;
  const excerpt = extractExcerptFromArticle(body, 160);

  return (
    <div className={styles.postCard}>
      {!!mainImage && (
        <div className={clsx(styles.postImageWrapper)}>
          <Link to={to} state={{ autoPlay: true }}>
            <PictureAsset image={mainImage} height={350} maxWidth={400} isFluid className={styles.postImage} />
            {hasVideo && <img className={styles.play} src={PlayButton} alt="Video Abspielen" />}
          </Link>
        </div>
      )}
      {!mainImage && <div className={styles.imagePlaceholder} />}
      <div className={styles.postDate}>{publishedDate}</div>
      <div className={styles.postContent}>
        <h3 className={styles.postTitle}>{title}</h3>
        <div>
          <p>{excerpt}</p>
          <LinkButton variant="primary" to={to} className={styles.postReadMore}>
            Artikel Lesen
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

PostCard.propTypes = {
  mainImage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  publishedDate: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  slug: PropTypes.string,
};

export default PostCard;
