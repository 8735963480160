import React from 'react';
import clsx from 'clsx';
import styles from './button.module.scss';

export function Button({ className = '', variant = 'default', showAlt = false, children, type = 'button', ...other }) {
  return (
    <button
      className={clsx(
        styles.button,
        variant === 'text' && styles.text,
        variant === 'primary' && styles.primary,
        variant === 'secondary' && styles.secondary,
        showAlt && styles.showAlternative,
        className
      )}
      aria-label="btn"
      type={type}
      {...other}
    >
      {children}
    </button>
  );
}
