import React from 'react';
import clsx from 'clsx';
import { ExternalLinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/external-link-button';
import Phone from '../../images/icons/phone.svg';
import styles from './cta-buttons.module.scss';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';
import { helpers } from 'gatsby-theme-sanity-evelan';

export const PrimaryCta = ({ className = '' }) => (
  <LinkButton to="/erstberatung" className={clsx(styles.actionButton, className)} variant="primary">
    Kostenlose Erstberatung
  </LinkButton>
);

export const SecondaryCta = ({ className = '', phone }) => (
  <ExternalLinkButton
    className={clsx(styles.actionButton, styles.secondaryCta, styles.btnImg, className)}
    variant="secondary"
    href={helpers.createPhoneLink(phone)}
    targetBlank={false}
  >
    <img src={Phone} alt="phone" />
    <span>ANRUFEN</span>
  </ExternalLinkButton>
);
