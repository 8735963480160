import React, { useState, useEffect, useRef } from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { BurgerMenu } from 'gatsby-theme-sanity-evelan/src/components/header/mobile-menu/burger-menu';
import { MobileMenu } from 'gatsby-theme-sanity-evelan/src/components/header/mobile-menu/mobile-menu';
import { useMedia } from 'gatsby-theme-sanity-evelan/src/hooks/useMedia';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';

import styles from './header.module.scss';
/**
 * @typedef {Object} HeaderProps
 * @property {MenuItem[]} menuItems
 *
 * @typedef {Object} MenuItem
 * @property {string} name
 * @property {string} link
 *
 * @param {HeaderProps} props
 */
export function Header({ menuItems }) {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(name: { eq: "favicon" }) {
        childImageSharp {
          fixed(
            quality: 100
            width: 79
            traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "transparent", color: "#8b181b" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const scrollCache = useRef({ pos: 0, debounce: false });

  const isDesktop = useMedia(['(min-width: 1200px)'], [true], false);

  useEffect(() => {
    const handleScroll = (e) => {
      const { debounce } = scrollCache.current;
      if (debounce) {
        window.cancelAnimationFrame(debounce);
      }
      scrollCache.current.debounce = window.requestAnimationFrame(function () {
        const lastPosition = scrollCache.current.pos;
        const y = window.pageYOffset;
        if (lastPosition < y) {
          if (!scrolled && (isDesktop ? y >= 100 : y >= 40)) {
            setScrolled(true);
          }
        } else {
          if (scrolled) {
            setScrolled(false);
          }
        }
        scrollCache.current.pos = window.pageYOffset;
      });
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolled, isDesktop, open, scrolled]);

  return (
    <>
      <div className={clsx(styles.headerWrapper, scrolled && styles.hideHeader)}>
        <Container component="header" variant="normal" className={styles.headerContainer}>
          <div className={styles.headerContent}>
            <div className={styles.logoContainer}>
              <Link to="/">
                <Img
                  className={styles.logoImage}
                  fixed={logo.childImageSharp.fixed}
                  loading="auto"
                  alt="PROFILIUM Logo"
                />
              </Link>
            </div>
            <nav>
              <ul className={styles.links}>
                {menuItems.map(({ name, link }) => {
                  return (
                    <li key={link}>
                      <LinkButton className={styles.link} to={link} variant="text" activeClassName={styles.activeNav}>
                        {name}
                      </LinkButton>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div className={styles.actions} />
            <div className={styles.iconWrapper}>
              {!isDesktop && <BurgerMenu isOpen={open} onClick={() => setOpen(!open)} />}
            </div>
          </div>
          {!isDesktop && <MobileMenu open={open} setOpen={setOpen} menuItems={menuItems} />}
        </Container>
      </div>
      <div className={clsx(styles.spacerBreaking, styles.spacer)} />
    </>
  );
}

Header.defaultProps = {
  menuItems: [{ name: 'Home', link: '/' }],
};

Header.propTypes = {
  menuItems: PropTypes.array,
};
