import React from 'react';
import { PinterestIcon } from 'react-share';
import clsx from 'clsx';
import styles from './social-icon.module.scss';

const SocialPinterestIcon = () => {
  const iconProps = {
    style: { verticalAlign: 'middle' },
    round: true,
    size: 36,
    bgStyle: {
      fill: 'rgba(0, 0, 0, 0)',
    },
    iconFillColor: '#201E1E',
  };

  return (
    <div className={clsx(styles.iconContainer, styles.pinterest)}>
      <PinterestIcon {...iconProps} />
    </div>
  );
};

export default SocialPinterestIcon;
