import React from 'react';
import clsx from 'clsx';
import styles from './social-icon.module.scss';
import InstagramIcon from '../../images/icons/social/instagram-icon.svg';
const SocialInstagramIcon = () => {
  return (
    <div className={clsx(styles.iconContainer, styles.instagram)}>
      <img src={InstagramIcon} width="20" height="20" alt="instagram" />
    </div>
  );
};

export default SocialInstagramIcon;
