import React from 'react';
import clsx from 'clsx';
import styles from './social-icon.module.scss';
import GoogleMapIcon from '../../images/icons/social/map-solid.svg';
const SocialGoogleMapIcon = () => {
  return (
    <div className={clsx(styles.iconContainer, styles.googleMap)}>
      <img src={GoogleMapIcon} width="20" height="20" alt="google map" />
    </div>
  );
};

export default SocialGoogleMapIcon;
