import React, { useEffect } from 'react';
import { animated as a, config, useSpring, useTrail } from 'react-spring';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';
import styles from './mobile-menu.module.scss';
import clsx from 'clsx';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { PrimaryCta } from '../cta-buttons';

const easingConfig = {
  ...config.default,
  easing: (t) => ((t *= 2) <= 1 ? 1 - Math.sqrt(1 - t * t) : Math.sqrt(1 - (t -= 2) * t) + 1) / 2,
};

const trailConfig = { mass: 5, tension: 3000, friction: 200 };

export const MobileMenu = ({ open, setOpen, menuItems }) => {
  useEffect(() => {
    if (open) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vhForMobile', `${vh}px`);
    }
  }, [open]);

  const cover = useSpring({
    height: `${open ? 100 : 0}%`,
    config: easingConfig,
    delay: open ? 100 : 220,
  });

  const menuTrail = useTrail(menuItems.length, {
    config: trailConfig,
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0.7)',
    delay: open ? 200 : 0,
  });

  return (
    <nav className={styles.nav} style={{ pointerEvents: open ? 'all' : 'none' }}>
      <a.div className={styles.menuCover} style={cover}>
        <Container variant="normal" className={styles.btnWrapper}>
          <ul className={styles.menuItems}>
            {menuTrail.map((style, index) => (
              <a.li key={`menu-item-key-${index}`} className={styles.menuItem} style={style}>
                <LinkButton to={menuItems[index].link} activeClassName={styles.activeNav}>
                  {menuItems[index].name}
                </LinkButton>
              </a.li>
            ))}
          </ul>
        </Container>
        <Container variant="normal" className={clsx(styles.ctaWrapper, open && styles.open)}>
          <PrimaryCta />
        </Container>
      </a.div>
    </nav>
  );
};
