import React from 'react';
import clsx from 'clsx';
import styles from './social-icon.module.scss';
import GooglePlusIcon from '../../images/icons/social/google-plus.svg';

const SocialGooglePlusIcon = () => {
  return (
    <div className={clsx(styles.iconContainer, styles.googlePlus)}>
      <img src={GooglePlusIcon} width="20" height="20" alt="Google Plus" />
    </div>
  );
};

export default SocialGooglePlusIcon;
