import React from 'react';
import { LinkedinIcon } from 'react-share';
import clsx from 'clsx';
import styles from './social-icon.module.scss';

const SocialLinkedinIcon = ({ className = '' }) => {
  const iconProps = {
    style: { verticalAlign: 'middle' },
    round: true,
    size: 36,
    bgStyle: {
      fill: 'rgba(0, 0, 0, 0)',
    },
    iconFillColor: '#201E1E',
  };

  return (
    <div className={clsx(styles.iconContainer, styles.linkedin, className)}>
      <LinkedinIcon {...iconProps} />
    </div>
  );
};

export default SocialLinkedinIcon;
