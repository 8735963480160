import React from 'react';
import clsx from 'clsx';

import styles from './checkbox.module.scss';

export function Checkbox({ id, checked, onChange, className }) {
  return (
    <input id={id} type="checkbox" checked={checked} onChange={onChange} className={clsx(styles.checkbox, className)} />
  );
}
