import React, { useState } from 'react';
import clsx from 'clsx';

import { Checkbox } from '../checkbox/checkbox';
import { useMedia } from 'gatsby-theme-sanity-evelan/src/hooks/useMedia';
import { ClickOutside } from 'gatsby-theme-sanity-evelan';

import DropdownIcon from '../../images/icons/sort-icon.svg';
import DropdownIconRed from '../../images/icons/sort-icon-red.svg';
import ResetIconRed from '../../images/icons/search-reset-icon-red.svg';

import styles from './dropdown.module.scss';

// Dropdown accepts a "options" prop that is equal to an array of objects with "id" and "name" property with both string values.
// Example options = [{id: '1', name: 'Item1'}, {id: '2', name: 'Item2'}]
// "alwaysShowLabel", "classname","beforeIcon", "onReset" and "error" props are optional.

export function Dropdown({
  label,
  value,
  options,
  className,
  alwaysShowLabel = false,
  beforeIcon,
  onSelect,
  onReset,
  error,
}) {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const isMobile = useMedia(['(max-width: 768px)'], [true], false);
  alwaysShowLabel = isMobile ? false : alwaysShowLabel;

  return (
    <ClickOutside onClick={() => setToggleDropdown(false)}>
      <div className={!!className ? `${styles.wrapper} ${className} ${error && styles.error}` : styles.wrapper}>
        <div
          className={clsx(styles.header, toggleDropdown && styles.headerOpen)}
          onClick={() => setToggleDropdown(!toggleDropdown)}
        >
          {beforeIcon && <img src={beforeIcon} className={styles.filterIcon} alt="Dropdown vor Symbol" />}
          <div className={clsx(styles.title, toggleDropdown && styles.red)}>
            {alwaysShowLabel && <p>{label}</p>}
            {alwaysShowLabel && value.length > 0 && <p>{options.find((item) => item.id === value[0]).name} </p>}
            {!alwaysShowLabel &&
              (value.length > 0 ? <p>{options.find((item) => item.id === value[0]).name} </p> : <p>{label}</p>)}
            {value.length > 1 && <span className={styles.count}>+ {value.length - 1}</span>}
          </div>
          <div className={styles.actions}>
            <img
              src={value.length > 0 ? ResetIconRed : toggleDropdown ? DropdownIconRed : DropdownIcon}
              className={styles.resetIcon}
              alt="Filter zurücksetzen"
              onClick={(e) => {
                e.stopPropagation();
                onReset();
                setToggleDropdown(false);
              }}
              style={{
                pointerEvents: value.length > 0 ? 'all' : 'none',
              }}
            />
          </div>
        </div>
        <ul className={clsx(styles.list, toggleDropdown && styles.visible)}>
          {options.map(({ id, name }, idx) => {
            return (
              <li key={`drop-down-opt-${idx}`}>
                <Checkbox
                  id={id}
                  checked={value.includes(id)}
                  name={name}
                  onChange={(e) => {
                    onSelect(e);
                    setToggleDropdown(true);
                  }}
                />
                <label htmlFor={id}>{name}</label>
              </li>
            );
          })}
        </ul>
        {!!error && <span className={styles.errorMessage}>{error}</span>}
      </div>
    </ClickOutside>
  );
}
